import { QUESTION_REQUEST_INITIATED, QUESTION_REQUEST_SUCCEED, QUESTION_REQUEST_FAILED } from "../action-type";
import produce from "immer";
import _ from 'lodash';

const initialState = {
  isLoading: false,
  data: [],
  error: null
}

export default function questionReducer( state=initialState, action ) {
  switch (action.type) {
    case QUESTION_REQUEST_INITIATED: {
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    }
    case QUESTION_REQUEST_SUCCEED: {
      return produce(state, draftState => {
        draftState.isLoading = false
        if(draftState.data.length && action.payload && Array.isArray(action.payload) && action.payload.length) {
          action.payload.forEach(question => {
            if(!_.find(draftState.data, ['id', question.id])) {
              draftState.data.push(question)
              draftState.error=null
            }
          });
        } else if(action.payload && Array.isArray(action.payload) && action.payload.length) {
          draftState.data = action.payload
        }
      })
    }
    case QUESTION_REQUEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    default: return state
  }
}