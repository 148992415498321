import React, { useState, useEffect } from 'react';
import ReactHighcharts from 'react-highcharts';

function GraphContainer(props) {
  const { chartData } = props
  const [ config, setCongig ] = useState({})

  useEffect(() => {
    if(chartData && Object.keys(chartData).length) {
      let c= {
        ...defaultConfig,
        chart: {
          ...defaultConfig.chart,
          height: 46 + (33 * chartData.length)
        },
        series: [{
          data: chartData
        }]
       }
      setCongig(c)
    }
  }, [chartData])
  
  if(config && Object.keys(config).length) {
    return (
      <table className="text-center skill-graph-container">
        <tbody>
          <tr>
            <td className="text-left text-weight-medium skill-header skill-header-name">Skill Area</td>
            <td className="text-center text-weight-medium skill-header skill-header-score">Score</td>
            <td rowSpan={props.chartData.length + 1} className="skill-graph-td">
             <div><ReactHighcharts config={config} /></div>
            </td>
          </tr>
          {chartData.map((data) => (
            <tr key={data.id}>
              <td className="text-left skill-label name">
                {data.name}
              </td>
              <td className="text-center skill-label data">
                {data.y}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  } else {
    return (
      <div className="ui grid height-full">
        <div className="sixteen wide middle aligned column text-align-center">
          <div className="ui active loader"></div>
          <p className="margin-top-sixty">Loading...</p>
        </div>
      </div>
    )
  }
}

export default GraphContainer;


const defaultConfig = {
  chart: {
    type: 'bar',
    plotBackgroundColor: '#EEEEEE',
    backgroundColor: 'rgba(0,0,0,0)',
    alignTicks: false,
  },
  plotOptions: {
    series: {
      pointWidth: "21",
    },
    bar: {
      label: {
        style: {
          fontSize: 22,
          fontWeight: 'bold'
        }
      }
    }
  },
  credits: {
    enabled: false
  },
  xAxis: {
    gridLineColor: '#ffffff',
    gridLineWidth: 0,
    lineWidth: 2,
    lineColor: '#EEEEEE',
    type: 'category',
    title: {
      enabled: false
    },
    endOnTick: false,
    tickWidth: 0,
    labels: {
      enabled: false
    },
  },
  yAxis: [{
    gridLineColor: '#ffffff',
    gridLineWidth: 3,
    minorGridLineWidth: 0,
    minorGridLineColor: '#ffffff',
    labels: {
      enabled: false
    },
    title: {
      enabled: false
    }
  },
  {
    opposite: true,
    gridLineColor: '#ffffff',
    gridLineWidth: 0,
    reversed: false,
    linkedTo: 0,
    labels: {
      style:{
        color: "#4A4A4A",
        fontSize: 14
      }
    },
    title: {
      enabled: false
    }
  }],
  title: {
    text: ''
  },
  legend: {
    enabled: false
  }
};