/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import withAssessmentController from '../../controllers/assessment-controller';
import ErrorView from '../error';
import { toast } from 'react-toastify';

function LandingPage(props) {
  const { assessmentModel, passageModel, questionModel } = props
  const [ isError, setIsError ] = useState(false)
  const [ error, setError ] = useState()

  //observer for required models data error
  useEffect(() => {
    if(assessmentModel && !assessmentModel.isLoading && assessmentModel.error) {
      setIsError(true)
      setError(assessmentModel.error)
      onError(assessmentModel.error)
    } else if(passageModel && !passageModel.isLoading && passageModel.error) {
      setIsError(true)
      onError(passageModel.error)
      setError(passageModel.error)
    } else if(questionModel && !questionModel.isLoading && questionModel.error) {
      setIsError(true)
      setError(questionModel.error)
      onError(questionModel.error)
    }
  }, [assessmentModel.isLoading, passageModel.isLoading, questionModel.isLoading])

  const onError = (e) => {
    if(e && e.errors && Array.isArray(e.errors) && e.errors.length) {
      e.errors.forEach(error => {
        if(error && error.message) {
          toast.error(error.message)
        } else {
          toast.error(error)
        }
      });
    } else if(e && e.message) {
      if(e && e.message) {
        toast.error(e.message)
      } else {
        toast.error(e)
      }
    } else {
      toast.error("Try again, Something went wrong")
    }
  }

  if(assessmentModel.isLoading || passageModel.isLoading || questionModel.isLoading) {
    return (
      <div className="ui grid height-full">
        <div className="sixteen wide middle aligned column text-align-center">
          <div className="ui active loader"></div>
          <p className="margin-top-sixty">Loading...</p>
        </div>
      </div>
    )
  } else if(isError) {
    return <ErrorView error={error}/>
  } else {
    return <div></div>
  }
}

export default withAssessmentController(LandingPage)