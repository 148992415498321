import React from 'react';
import SanitizeContent from '../../controllers/sanitize-content';

function QuestionContent({isReport, children, content, pIndex}) {
  if (isReport) {
    return (
      <>
        <table className="padding-bg padding-no-bottom margin-top-twenty report-image">
          <tbody>
            <tr>
              <td  className="padding-md-right print-report-question-content-p-index question">
              {pIndex}.
              </td>
              <td>
                <SanitizeContent 
                  type="p"
                  content={content}
                  className="text-justify print-report-question-content-passage"
                />
              </td>
            </tr>
          </tbody>
        </table>
        {children}
      </>
    )
  } else {
    return(
      <SanitizeContent 
        type="div"
        content={content}
        className="ui segment border-none box-shadow-none padding-sm-bottom"
      />
    )  
  }
} 

export default QuestionContent;