import { ASSESSMENT_REQUEST_INITIATED, ASSESSMENT_REQUEST_SUCCEED, ASSESSMENT_REQUEST_FAILED} from '../action-type';

const initialState = {
  isLoading: false,
  data: {},
  error: null
}

export default function AssessmentReducer(state=initialState, action) {
  switch (action.type) {
    case ASSESSMENT_REQUEST_INITIATED:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: state.data
      }
    case ASSESSMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload
      }
    case ASSESSMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: state.data
      }
    default:
      return state
  }
}