import { ANSWER_REQUEST_FAILED, ANSWER_REQUEST_INITIATED, ANSWER_REQUEST_SUCCEED } from "../action-type"
import { createRecord } from "../async-actions"
import { onError } from "./general-action"

export function createAnswer(data) {
  return async function(dispatch) {
    try {
      dispatch(answerRequestInitiated())
      let res = await createRecord('answer', data)
      return dispatch(answerRequestSucceed(res.data))
    } catch (e) {
      return dispatch(onError(ANSWER_REQUEST_FAILED, e))
    }
  }
}

const answerRequestInitiated = () => {
  return {
    type: ANSWER_REQUEST_INITIATED
  }
};

const answerRequestSucceed = (payload) => {
  return {
    type: ANSWER_REQUEST_SUCCEED,
    payload: payload
  }
};