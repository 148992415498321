import React from 'react';
import SanitizeContent from '../../controllers/sanitize-content';

function QuestionOption({option}) {
  return (
    <>
      <table className="padding-bg padding-no-bottom padding-no-top">
        <tbody>
          <tr>
            <td>
              <ol type="A" className="margin-no">
                {option.map((option, oIndex) => (
                  <li key={option.id} className="print-report-question-content-passage">
                    {option.is_selected && 
                      <span className="print-report-question-option"><img src="/assets/images/tick.svg" alt='' /></span>
                    }
                    <SanitizeContent 
                      type="span"
                      content={option.content}
                    />
                  </li>
                ))}
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default QuestionOption;