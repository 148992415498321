import React from 'react';
import SanitizeContent from '../controllers/sanitize-content';

function ErrorView(props) {
  const { error, location } = props
  const data = error ? error : location.state
  return (
    <>
      <div className="ui centered middle aligned grid height-full">
        <div className="eight wide centered column flex-middle-align">
          <div className="ui segments border-none box-shadow-none">
          <div className="ui segment bg-transparent box-shadow-none border-none padding-no">
            <img src="/assets/images/qton_half_oops.png" alt="qton hey" className="error-image" />
          </div>
            <div className="ui very padded center aligned segment error-container">
              <h1 className="text-hacker">OOPS!</h1>
              <SanitizeContent type="p" content={data && data.message} className="text-hacker text-size-normal"/>
              <div className="ui divider margin-top-thirty contact-support-visibility"></div>
              <div className="ui grid contact-support-visibility">
                <div className="ui center aligned centered column row">
                  Contact Support :
                  <a href="mailto:support@talview.com?&subject=Page%20Not%20Found?&body=Page%20not%20found" target="blank" className="text-color-positive">
                    support@talview.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorView;