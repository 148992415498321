import React, { useEffect } from 'react';
import './App.less';
import { Provider } from 'react-redux';

import Navigation from './navigation';
import store from './store';
import { toast } from 'react-toastify';
// import { onNetworkAvailable, onNetworkUnavailable } from './store/actions/connectivity-action';

toast.configure({
  position: "bottom-right"
})

function App() {
  useEffect(() => {
    // navigator.connection.onchange = function () {
    //   if(navigator.onLine) {
    //     store.dispatch(onNetworkAvailable())
    //   } else {
    //     store.dispatch(onNetworkUnavailable())
    //   }
    // }
  })
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
}

export default App;
