import { PASSAGE_REQUEST_INITIATED, PASSAGE_REQUEST_SUCCEED, PASSAGE_REQUEST_FAILED } from "../action-type";

const initialState =  {
  isLoading: false,
  data: [],
  error: null
}

export default function passageReducer(state=initialState, action) {
  switch (action.type) {
    case PASSAGE_REQUEST_INITIATED: {
      return {
        ...state,
        isLoading: true,
        error: null
      }
    }
    case PASSAGE_REQUEST_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null
      }
    }
    case PASSAGE_REQUEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    default: return state
  }
}