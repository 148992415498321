import React from 'react';

function ErrorMessage(props){
  return (
    <div className="ui floating-error error message margin-no">
      <div className="content">
        Answer this question to go to the next one
        <span className="cursor-pointer float-right" onClick={() => props.handleDismiss(false)} >
          <img src="/assets/images/close.svg" className="close-icon" alt="" />
        </span>
      </div>
    </div>
  )
}
export default ErrorMessage