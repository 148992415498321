import { REPORT_REQUEST_INITIATED, REPORT_REQUEST_SUCCEED, REPORT_REQUEST_FAILED } from '../action-type';
import { findRecord, query } from '../async-actions';
import { onError } from './general-action';
import { sortBy } from 'lodash';

export const getReport = (report_id) => {
  return async function(dispatch) {
    dispatch(reportRequestInitiated())
    try {
      let response = await findRecord('report', report_id)
      if(Array.isArray(response.data) && response.data.length) {
        return dispatch(reportRequestSucceed(response.data[0]))
      } else {
        return dispatch(reportRequestSucceed(response.data))
      }
    } catch (e) {
      return dispatch(onError(REPORT_REQUEST_FAILED, e))
    }
  }
};

export const queryReport = (q) => {
  return async function(dispatch) {
    dispatch(reportRequestInitiated())
    try {
      let response = await query('report', q)
      if(response && response.data && Array.isArray(response.data) && response.data.length) {
        return dispatch(reportRequestSucceed(response.data[0]))
      } else {
        return dispatch(reportRequestSucceed(response.data))
      }
    } catch (e) {
      return dispatch(onError(REPORT_REQUEST_FAILED, e))
    }
  }
}

const reportRequestInitiated = () => {
  return {
    type: REPORT_REQUEST_INITIATED,
  }
};

const reportRequestSucceed = (data) => {
  if(data && Object.keys(data).length) {
    let { assessment } = data
    assessment.passage = sortBy(assessment.passage, ['sort_order'])
    assessment.passage = assessment.passage.map((p) => {
      p.question = sortBy(p.question, ['sort_order'])
      p.question.option = sortBy(p.question.option, ['sort_order'])
      return p
    })
    return {
      type: REPORT_REQUEST_SUCCEED,
      payload: data
    }
  } else {
    return {
      type: REPORT_REQUEST_SUCCEED,
      payload: data
    }
  }
};