/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../buttons/button';
import OptionView from '../option-view';
import { find, head, findIndex } from 'lodash';
import { connect } from 'react-redux';
import { useParams, generatePath } from 'react-router-dom';
import { Form } from "semantic-ui-react";
import ErrorMessage from "../../components/error-message";
import SanitizeContent from '../../controllers/sanitize-content';

function PassageSectionView(props) {
  const { currentPassage, appState,
    answerModel, onError, currentQuestion, setCurrentQuestion,
    updateStateAndRedirect, selectedOption, setOption, saveAnswer, currentIndex,
    toogleError, showError } = props
  const { assessment_id, passage_id, question_id } = useParams()
  const { questionStat } = appState.data
  const { isDisable, setDisable } = props
  const [ currentQuestionIndex, setQuestionIndex ] = useState()

  // ------------------- Hooks ---------------------------

  //Setting current displayable question and index
  useEffect(() => {
    if(currentPassage && currentPassage.passage_id && question_id) {
      setQuestionIndex(findIndex(currentPassage.questions, ['question_id', parseInt(question_id)]))
      setCurrentQuestion(find(currentPassage.questions, ['question_id', parseInt(question_id)]))
    }
  }, [currentPassage, question_id])

  //Observer to observer answer submission and fetching the next question
  useEffect(() => {
    if(!answerModel.isLoading && answerModel.data && answerModel.data.length && !answerModel.error) {
      if(currentQuestion && currentQuestion.question_id && find(answerModel.data, ['question_id', currentQuestion.question_id])) {
        nextQuestion({ "passage_id": currentPassage.passage_id, "question_id": currentQuestion.question_id, "option_id": selectedOption.id })
      }
    } else if(!answerModel.isLoading && answerModel.error) {
      onError(answerModel.error)
    }
  }, [answerModel.isLoading, answerModel.data, answerModel.error])

  // -------------------- Methods ------------------------------

  //contructing next state object and rediret
  const updateState = async (passageId, next_question_id, qs) => {
    let path = generatePath(props.match.path, {
      assessment_id: assessment_id,
      passage_id: passageId,
      question_id: next_question_id
    })
    const currentStat = { passage_id: parseInt(passageId), question_id: next_question_id }
    return updateStateAndRedirect(path, currentStat, qs, 'array')
  }

  //checking for next state
  const nextQuestion = async (data, isAnswered=true) => {
    let qs = { ...data, isAnswered: isAnswered }
    setOption({})
    try {
      if(currentQuestion && currentQuestion.next_question_id) {
        updateState(passage_id, currentQuestion.next_question_id, qs)
      } else if(currentPassage && currentPassage.next_passage_id) {
        let next_question_id = head(find(appState.data.passages, ["passage_id", currentPassage.next_passage_id]).questions).question_id
        updateState(currentPassage.next_passage_id, next_question_id, qs)
      } else {
        const currentStat = { passage_id: null, question_id: null }
        updateStateAndRedirect('/assessment/post-section', currentStat, qs)
      }
    } catch (e) {
      onError(e)
    }
  }

  //skipping current question
  const skipQuestion = () => {
    nextQuestion({
      "passage_id": currentPassage.passage_id,
      "question_id": currentQuestion.question_id,
      "option_id": (selectedOption && Object.keys(selectedOption).length) ? selectedOption.id : null
    },false)
  }

  if(currentQuestion && currentQuestion.content) {
    return (
      <div className="ui segments border-none box-shadow-none question-image">
        <div className="ui segment border-none box-shadow-none padding-vs">
          <div className="ui segments border-none box-shadow-none margin-bottom-no question-height text-justify">
            <div className="ui segment border-none box-shadow-none">
              <span className="margin-right-five">{currentIndex + 1}.{currentQuestionIndex + 1}</span>
              <SanitizeContent type="span" content={currentQuestion.content} />
            </div>
            <div className="ui segment border-none box-shadow-none">
              <Form>
              {
                currentQuestion.options.map((option) => (
                  <OptionView
                    key={option.id}
                    currentQuestion={currentQuestion}
                    onSelectOption={setOption}
                    option={option}
                    selectedOption={selectedOption}
                    isDisable ={isDisable}
                    setDisable ={setDisable}
                    questionStat={questionStat}
                    toogleError={toogleError}
                    />
                ))
              }
              </Form>
            </div>
          </div>
          { showError && 
            <ErrorMessage handleDismiss={toogleError}/>
          }
        </div>
        <div className="ui right aligned segment box-shadow-none">
        { appState.data.isSkipEnable && 
            <Button label="Skip" className="ui tv-secondary brand basic button" onClick={skipQuestion} isDisable={answerModel.isLoading}/>
          }
          <Button label="Next" className={`ui brand button ${appState.data.isSkipEnable && 'float-right'}`} onClick={saveAnswer} isLoading={answerModel.isLoading}/>
        </div>
      </div>
    )
  } else {
    return (
      <div className="ui grid height-full">
        <div className="sixteen wide middle aligned column text-align-center">
          <div className="ui active loader"></div>
          <p className="margin-top-sixty">Loading...</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  appState: state.appState,
  answerModel: state.answerModel
})

export default connect(mapStateToProps)(PassageSectionView);
