import React from 'react';
import SanitizeContent from '../../controllers/sanitize-content';

function QuestionList({isReport, children, content, pIndex, qIndex}) {
  if (isReport) {
    return (
      <>
        <div className="full-width print-report-question-content-border">&nbsp;</div>
        <table className="padding-bg padding-no-bottom report-image">
          <tbody>
            <tr>
              <td className="print-report-question-content-p-index">
                {pIndex}.{qIndex}
              </td>
              <td>
                <SanitizeContent
                  type="p"
                  content={content}
                  className="text-justify"
                />
              </td>
            </tr>
          </tbody>
        </table>
        {children}
      </>
    )
  } else {
    return(
      <SanitizeContent
        type="div"
        content={content}
        className="ui segment border-none box-shadow-none padding-sm-bottom"
      />
    )  
  }
} 

export default QuestionList;