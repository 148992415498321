import { QUESTION_REQUEST_INITIATED, QUESTION_REQUEST_SUCCEED, QUESTION_REQUEST_FAILED, ANSWER_REQUEST_SUCCEED } from '../action-type';
import { findRecord, query } from '../async-actions';
import { sortBy } from 'lodash';
import { onError } from './general-action';

export const getQuestion = (question_id) => {
  return async function(dispatch) {
    dispatch(questionRequestInitiated())
    try {
      let response = await findRecord('question', question_id)
      return dispatch(questionRequestSucceed([response.data]))
    } catch (e) {
      return dispatch(onError(QUESTION_REQUEST_FAILED, e))
    }
  }
};

export const getAllQuestions = (q) => {
  return async function(dispatch) {
    dispatch(questionRequestInitiated())
    try {
      let response = await query('question', q)
      return dispatch(questionRequestSucceed(response.data))
    } catch (e) {
      return dispatch(onError(QUESTION_REQUEST_FAILED, e))
    }
  }
};

const questionRequestInitiated = () => {
  return {
    type: QUESTION_REQUEST_INITIATED,
  }
};

const questionRequestSucceed = (data) => {
  return function(dispatch) {
    if(data && data.length) {
      data = sortBy(data, ['sort_order'])
      let length = data.length
      data = data.map((question, index) => {
        if(length > (index + 1)) {
          question.next_question_id = data[index+1].id
        } else {
          question.next_question_id = null
        }
        question.option = sortBy(question.option, ['sort_order'])
        if(question.answer && typeof(question.answer) === 'object' && Object.keys(question.answer).length) {
          question.answer.passage_id = question.passage_id
          dispatch({
            type: ANSWER_REQUEST_SUCCEED,
            payload: question.answer
          })
        }
        return question
      })
    }
    return dispatch({
      type: QUESTION_REQUEST_SUCCEED,
      payload: data
    })
  }
};