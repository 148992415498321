import { query } from "../async-actions"
import { onError } from "./general-action"
import { ASSESSMENT_COMPLETE_REQUEST_SUCCEED, ASSESSMENT_COMPLETE_REQUEST_INITIATED, ASSESSMENT_COMPLETE_REQUEST_FAILED } from "../action-type"

export const getAssessmentComplete = () => {
  return async function(dispatch) {
    try {
      dispatch(onRequestInitiated())
      let res = await query('invite/complete', {})
      return dispatch(onRequestSucceed(res.data))
    } catch (e) {
      return dispatch(onError(ASSESSMENT_COMPLETE_REQUEST_FAILED, e))
    }
  }
}

const onRequestSucceed = (data) => {
  return {
    type: ASSESSMENT_COMPLETE_REQUEST_SUCCEED,
    payload: data
  }
}
const onRequestInitiated = () => {
  return {
    type: ASSESSMENT_COMPLETE_REQUEST_INITIATED
  }
}