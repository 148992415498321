import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLogo, initializeAxiosHeader } from '../store/async-actions';
import { ToastMessageContainer, showToast } from "./NotificationContainer/NotificationToast"
import 'react-toastify/dist/ReactToastify.css';
import { DEFAULT_LOGO_URL } from '../globals/constants';

function Logo({ appState }) {
  const [logoUrl, setLogoUrl] = useState(null);


  useEffect(() => {
    async function fetchLogo() {
      try {
        const token = appState?.data?.c;
        if (token) {
          await initializeAxiosHeader(token);
          const responseData = await getLogo();
          setLogoUrl(responseData.logo_url);
        } 
      } catch (error) {
        showToast(`Failed to fetch logo. Using default logo.`, { type: 'error' });
        setLogoUrl(DEFAULT_LOGO_URL);
      }
    }
    fetchLogo();
  }, [appState?.data?.c]);

  return (
    <div>
      <div className={`item logo`} id="logo-banner" style={{ backgroundImage: `url(${logoUrl})` }}></div>
      <ToastMessageContainer position="bottom-right" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  appState: state.appState,
});

export default connect(mapStateToProps)(Logo);
