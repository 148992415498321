export const ASSESSMENT_REQUEST_INITIATED = "ASSESSMENT_REQUEST_INITIATED"
export const ASSESSMENT_REQUEST_SUCCEED = "ASSESSMENT_REQUEST_SUCCEED"
export const ASSESSMENT_REQUEST_FAILED = "ASSESSMENT_REQUEST_FAILED"

export const PASSAGE_REQUEST_INITIATED = "PASSAGE_REQUEST_INITIATED"
export const PASSAGE_REQUEST_SUCCEED = "PASSAGE_REQUEST_SUCCEED"
export const PASSAGE_REQUEST_FAILED = "PASSAGE_REQUEST_FAILED"

export const QUESTION_REQUEST_INITIATED = "QUESTION_REQUEST_INITIATED"
export const QUESTION_REQUEST_SUCCEED = "QUESTION_REQUEST_SUCCEED"
export const QUESTION_REQUEST_FAILED = "QUESTION_REQUEST_FAILED"

export const REPORT_REQUEST_INITIATED = "REPORT_REQUEST_INITIATED"
export const REPORT_REQUEST_SUCCEED = "REPORT_REQUEST_SUCCEED"
export const REPORT_REQUEST_FAILED = "REPORT_REQUEST_FAILED"

export const OPTION_REQUEST_INITIATED = "OPTION_REQUEST_INITIATED"
export const OPTION_REQUEST_SUCCEED = "OPTION_REQUEST_SUCCEED"
export const OPTION_REQUEST_FAILED = "OPTION_REQUEST_FAILED"

export const ANSWER_REQUEST_INITIATED = "ANSWER_REQUEST_INITIATED"
export const ANSWER_REQUEST_SUCCEED = "ANSWER_REQUEST_SUCCEED"
export const ANSWER_REQUEST_FAILED = "ANSWER_REQUEST_FAILED"

export const ASSESSMENT_COMPLETE_REQUEST_INITIATED = "ASSESSMENT_COMPLETE_REQUEST_INITIATED"
export const ASSESSMENT_COMPLETE_REQUEST_SUCCEED = "ASSESSMENT_COMPLETE_REQUEST_SUCCEED"
export const ASSESSMENT_COMPLETE_REQUEST_FAILED = "ASSESSMENT_COMPLETE_REQUEST_FAILED"

export const ADD_ITEM_TO_APP_STATE = "ADD_ITEM_TO_APP_STATE"
export const ADD_BULK_TO_APP_STATE = "ADD_BULK_TO_APP_STATE"
export const CLEAR_APP_STATE = "CLEAR_APP_STATE"
export const REMOVE_ITEM_FROM_APP_STATE = "REMOVE_ITEM_FROM_APP_STATE"
export const APPEND_ITEM_TO_APP_STATE = "APPEND_ITEM_TO_APP_STATE"

export const NETWORK_AVAIABLE = "NETWORK_AVAIABLE"
export const NETWORK_UNAVAIABLE = "NETWORK_UNAVAIABLE"
