import { ASSESSMENT_REQUEST_INITIATED, ASSESSMENT_REQUEST_SUCCEED, ASSESSMENT_REQUEST_FAILED } from '../action-type';
import { findRecord, query } from '../async-actions';
import { onError } from './general-action';

export const getAssessment = (assessment_id) => {
  return async function(dispatch) {
    dispatch(assessmentRequestInitiated())
    try {
      let response = await findRecord('assessment', assessment_id)
      if(Array.isArray(response.data) && response.data.length) {
        return dispatch(assessmentRequestSucceed(response.data[0]))
      } else {
        return dispatch(assessmentRequestSucceed(response.data))
      }
    } catch (e) {
      return dispatch(onError(ASSESSMENT_REQUEST_FAILED, e))
    }
  }
};

export const queryAssessment = (q) => {
  return async function(dispatch) {
    dispatch(assessmentRequestInitiated())
    try {
      let response = await query('assessment', q)
      if(response && response.data && Array.isArray(response.data) && response.data.length) {
        return dispatch(assessmentRequestSucceed(response.data[0]))
      } else {
        return dispatch(assessmentRequestSucceed(response.data))
      }
    } catch (e) {
      return dispatch(onError(ASSESSMENT_REQUEST_FAILED, e))
    }
  }
}

const assessmentRequestInitiated = () => {
  return {
    type: ASSESSMENT_REQUEST_INITIATED,
  }
};

const assessmentRequestSucceed = (payload) => {
  return {
    type: ASSESSMENT_REQUEST_SUCCEED,
    payload: payload
  }
};