import React from "react";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

 function ToastMessageContainer({position="bottom-right"}){
    return (
             <ToastContainer position={position}/>
    )

}
const showToast=(message,options={})=>{
    toast(message,options)
}
export {ToastMessageContainer,showToast}

