/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { find, findIndex } from 'lodash';
import { connect } from 'react-redux';
import SanitizeContent from '../controllers/sanitize-content';

function QuestionView(props) {
  const { passage_id } = useParams()
  const { appState, setIndex } = props
  const { currentPassage, setCurrentPassage } = props

  //observer for setting current passage
  useEffect(() => {
    if(appState && appState.data && appState.data.passages && passage_id) {
      setIndex(findIndex(appState.data.passages, ['passage_id', parseInt(passage_id)]))
      setCurrentPassage(find(appState.data.passages, ['passage_id', parseInt(passage_id)]))
    }
  }, [appState.data.passages, passage_id])

  if(currentPassage && currentPassage.content) {
    return (
      <div className="ui segments border-none box-shadow-none question-image">
        
        <SanitizeContent
          type="div"
          content={currentPassage.content}
          className="ui segment border-none box-shadow-none padding-sm-bottom text-justify"
        />
      </div>
    )
  } else {
    return <div></div>
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(QuestionView);