import { ANSWER_REQUEST_INITIATED, ANSWER_REQUEST_SUCCEED, ANSWER_REQUEST_FAILED } from "../action-type";
import { produce } from 'immer';
import { find, remove } from 'lodash';

const initialState = {
  isLoading: false,
  error: null,
  data: []
}

export default function answerReducer(state=initialState, action) {
  switch (action.type) {
    case ANSWER_REQUEST_INITIATED: {
      return {
        ...state,
        isLoading: true,
        error: null
      }
    }
    case ANSWER_REQUEST_SUCCEED: {
      return produce(state, draftState => {
        if(draftState.data && draftState.data.length) {
          let old = find(draftState.data, ['id', action.payload.id])
          if(!old) {
            draftState.data.push(action.payload)
          } else {
            remove(draftState.data, (data) => data.id === action.payload.id)
            draftState.data.push(action.payload)
          }
        } else {
          draftState.data.push(action.payload)
        }
        draftState.isLoading = false
        draftState.error = null
      })
    }
    case ANSWER_REQUEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    default: return state
  }
}