import React, { useState, useEffect } from 'react';
import { getLogo, initializeAxiosHeader } from '../../store/async-actions';
import { ToastMessageContainer,showToast } from '../NotificationContainer/NotificationToast';
import 'react-toastify/dist/ReactToastify.css';
import { DEFAULT_LOGO_URL } from '../../globals/constants';

function CoverPage(props) {

  const [logoUrl, setLogoUrl] = useState(null);
  const [orgName,setOrgName]=useState(null)

  useEffect(() => {
    async function fetchLogo() {
      console.log("fetching logo")
      try {
        const token = props.c;
        if (token) {
          await initializeAxiosHeader(token);
          const responseData = await getLogo();

          setLogoUrl(responseData.logo_url);
          setOrgName(responseData.organization_name)
        }
      } catch (error) {
        showToast(`Failed to fetch logo. Using default logo.`, { type: 'error' });
        setLogoUrl(DEFAULT_LOGO_URL);
      }
    }
    fetchLogo()
  }, [props?.c]);

  return (
    <>
      <img src='/assets/images/bg2.jpg' className="print-report-cover" alt="" />
      {logoUrl && (
        <img src={logoUrl} className="print-report-cover-logo-header" alt="Logo" />
      )}
      <p className="text-weight-bold text-size-medium print-report-cover-org org-name">{orgName}</p>
      <p className="text-weight-bold text-size-medium print-report-cover-org org-report">CASE STUDY REPORT</p>
      <div className="ui grid margin-no padding-sm-top">
        <div className="ten wide column padding-bg">
          <div className="ui grid">
            <div className="twelve wide middle aligned column margin-top-thirty">
              <div className="text-size-small padding-bg-left">
                <div>Name: <span className="text-weight-medium padding-vs">{props.fullName}</span></div>
                <div>Role: <span className="text-weight-medium padding-vs">{props.data.assessment && props.data.assessment.name}</span></div>
                <div>Date of Test: <span className="text-weight-medium padding-vs">{props && props.formattedDate}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="six wide right aligned column padding-md">
          {logoUrl && (
            <img src={logoUrl} className="print-report-cover-logo-footer" alt="ZoomRx Logo" />
          )}
        </div>
      </div>
    </>
  )
}
export default CoverPage
