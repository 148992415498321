import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import AssessmetReducer from './reducers/assessment-reducer';
import AppStateReducer from './reducers/app-state-reducer';
import ConnectionReducer from './reducers/connectivity-reducer';
import PassageReducer from './reducers/passage-reducer';
import QuestionReducer from './reducers/question-reducer';
import OptionReducer from './reducers/option-reducer';
import AnswerReducer from './reducers/answer-reducer';
import ReportReducer from './reducers/report-reducer';
import AssessmentCompleteReducer from './reducers/assessment-complete-reducer';

const reducers =  combineReducers({
  assessmentModel: AssessmetReducer,
  appState: AppStateReducer,
  networkAvailability: ConnectionReducer,
  passageModel: PassageReducer,
  questionModel: QuestionReducer,
  optionModel: OptionReducer,
  answerModel: AnswerReducer,
  reportModel: ReportReducer,
  inviteStatus: AssessmentCompleteReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)))

export default store;
