import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LandingPage from '../pages/assessment/init';
import PostSection from '../pages/assessment/post-section';
import Passage from '../pages/assessment/passage';
import Report from '../pages/report';
import ErrorView from '../pages/error';
import Logo from '../components/Logo';

export default function Navigation() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/report" exact component={Report} />
        <ContainerRoute/>
      </Switch>
    </BrowserRouter>
  )
}

const ContainerRoute = () => {
  return (
    <div className="ui container height-view-port">
      <Logo />
      <div className="ui grid height-view-port">
        <div className="sixteen wide column margin-top-hundred padding-no-top">
          <Switch>
            <Route path="/assessment/post-section" exact component={PostSection} />
            <Route path="/assessment/init" exact component={LandingPage} />
            <Route path="/assessment/:assessment_id" component={Passage}/>
            <Route path="/error" component={ErrorView} />
            <Route path="/" exact/>
            <Route path="*" render={() => <ErrorView error={{message: "No Route Found."}} />}/>
          </Switch>
        </div>
      </div>
    </div>
  )
}