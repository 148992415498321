/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import SanitizeContent from '../controllers/sanitize-content';

function OptionView(props) {
  const { option, currentQuestion, selectedOption, toogleError, questionStat } = props
  const [ isChecked, setChecked ] = useState()

  //observer for setting isChecked true
  useEffect(() => {
    if(selectedOption && Object.keys(selectedOption).length) {
      let checked = selectedOption.id == option.id
      setChecked(checked)
    }
  }, [selectedOption.id, currentQuestion.question_id])

  useEffect(() => {
    if(questionStat && questionStat.length && option && option.id) {
      if(find(questionStat, {"option_id": option.id, "question_id":  currentQuestion.question_id})) {
        handleSelectedOption()
      }
    }
  }, [])

  const handleSelectedOption = () => {
    toogleError(false)
    props.onSelectOption(props.option);
  }

  if(currentQuestion && option){
    return (
      <div className="field">
        <div className={`ui ${isChecked && 'checked'} radio checkbox`} onClick={handleSelectedOption}>
          <input className="hidden" id={props.option.id} name={`${currentQuestion.question_id}`} type="radio" value={props.option.content} defaultChecked={isChecked} readOnly/>
          <SanitizeContent type="label" content={props.option.content} htmlFor={props.option.id}/>
        </div>
      </div>
    )
  } else {
    return (
      <div className="field">
        <label className="text-weight-normal text-size-small">Loading..</label>
      </div>
    )
  }
}
export default OptionView;
