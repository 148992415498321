import { REPORT_REQUEST_INITIATED, REPORT_REQUEST_SUCCEED, REPORT_REQUEST_FAILED} from '../action-type';

const initialState = {
  isLoading: false,
  data: {},
  error: null
}

export default function ReportReducer(state=initialState, action) {
  switch (action.type) {
    case REPORT_REQUEST_INITIATED:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: state.data
      }
    case REPORT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload
      }
    case REPORT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: state.data
      }
    default:
      return state
  }
}