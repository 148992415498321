/* eslint-disable eqeqeq */
import React from 'react';
import CreateReactClass from 'create-react-class';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { queryAssessment } from '../store/actions/assessment-action';
import { addBulk, getAppState, clearState } from '../store/actions/app-state-action';
import { getAllPassage } from '../store/actions/passage-action';
import { getAllQuestions } from '../store/actions/question-action';
import { initializeAxiosHeader } from '../store/async-actions';
import { find, filter, flatten } from 'lodash';
import { generatePath } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

const withAssessmentController = Page => {
  const hocComponent = CreateReactClass({
    getInitialState: function() {
      this.props.fetchAppState()
      return this.state
    },

    componentDidMount: function() {
      const { match, location } = this.props
      const { c, i } = parse(location.search)
      
      this.props.fetchAppState().then(({ data }) => {
        const { params, path } = match
        if(path.includes("/assessment/:assessment_id") && data && data && Object.keys(data).length && params.assessment_id && params.assessment_id == data.assessment_id) {
          initializeAxiosHeader(data.c)
          this.setState({c: data.c, invite_id: data.invite_id})
          this.redirectTo()
        } else if(c && data && c == data.c) {
          this.setState({c: c, invite_id: i})
          initializeAxiosHeader(data.c)
          this.redirectTo()
        } else if(c) {
          this.setState({ c: c, invite_id: i })
          initializeAxiosHeader(c)
          this.initializeApplication(i)
        } else if (data && data.c) {
          this.setState({ c: c, invite_id: data.invite_id })
          initializeAxiosHeader(data.c)
          this.redirectTo()
        } else {
          this.props.history.replace('/error', {message: "Invalid Entry/Assessment not found"})
          Sentry.captureException("Invalid Entry")
        }
      }).then(() => Sentry.configureScope((scope) => scope.setUser({"invite": this.state && Object.keys(this.state).length ? this.state.c : null})))
    },

    initializeApplication: async function() {
      try {
        const { fetchAssessment, fetchPassages, fetchQuestions } = this.props
        const assessment = await fetchAssessment()
        const passages = await fetchPassages({assessment_id: assessment.payload.id})
        await Promise.all(passages.payload.map(async (passage) => await fetchQuestions({passage_id: passage.id, expand: 'option'})))
        return this.createAppState()
      } catch (e) {
        Sentry.captureException(e)
      }
    },

    generateCurrentStat: (questions, passages) => {
      let sortedQuestionList  = passages.map((passage) => filter(questions, ['passage_id', passage.id]))
      let currentQuestion = find(flatten(sortedQuestionList), (question) => {
        if(question.answer && Object.keys(question.answer).length && question.answer.option_id) {
          return false
        } else {
          return true
        }
      })
      if(currentQuestion && Object.keys(currentQuestion)) {
        return { passage_id: currentQuestion.passage_id, question_id: currentQuestion.id }
      } else {
        return { passage_id: null, question_id: null }
      }
    },

    createAppState: async function() {
      try {
        const { assessmentModel, passageModel, questionModel, questionStat, addBulkToAppState } = this.props
        const { id: assessment_id, organization_id, isSkipEnable } = assessmentModel.data
        let data = {}
        if(Object.keys(assessmentModel.data).length && Array.isArray(passageModel.data) && passageModel.data.length && Array.isArray(questionModel.data) && questionModel.data.length) {
          let currentStat = this.generateCurrentStat(questionModel.data, passageModel.data)
          data = {
            assessment_id, organization_id, isSkipEnable,
            c                             : this.state.c,
            invite_id                     : this.state.invite_id, 
            totalPassage                  : passageModel.data.length,
            currentStat                   : currentStat,
            questionStat                  : questionStat,
            passages: passageModel.data.map((passage) => {
              const { id: passage_id, content, next_passage_id } = passage
              const questions = filter(questionModel.data, ["passage_id", passage_id])
              return {
                passage_id,
                content,
                next_passage_id,
                totalQuestion: questionModel.data.length,
                questions: questions.map((question) => {
                  const { id: question_id, content, options, option, next_question_id } = question
                  let op = options ? options : option //Need to remove
                  return {
                    question_id,
                    content,
                    next_question_id,
                    options: op.map(({id, content}) => ({id, content}))
                  }
                })
              }
            })
          }
        } else {
          Sentry.configureScope((scope) => {
            scope.setExtras([
              { assessmentPayload: { error: assessmentModel.error, id: assessmentModel.data.id } },
              { passagesPayload: { error: passageModel.error, data: passageModel.data.length} },
              { questionPayload: { error: questionModel.error, data: questionModel.data.length} }
            ])
          });
          throw new Error("Either assessment or passage or question is not available")
        }
        await addBulkToAppState(data)
        return this.redirectTo()
      } catch(e) {
        this.props.history.replace('/error', e)
        Sentry.captureException(e)
      }
    },
    
    redirectTo: function() {
      const {assessment_id, currentStat } = this.props.appState.data
      if(currentStat && currentStat.passage_id && currentStat.question_id) {
        let path = generatePath("/assessment/:assessment_id/passage/:passage_id/question/:question_id", {
          assessment_id: assessment_id,
          passage_id: currentStat.passage_id,
          question_id: currentStat.question_id
        })
        return this.props.history.replace(path)
      } else {
        this.props.clearAppState()
        return this.props.history.replace('/error', { 
          message: "<span>Seems like assessment is already completed. Click on <b>Finish</b> button to complete the assessment.</span>" 
        })
      }
    },

    render: function() {
      return <Page {...this.props} {...this.state} />;
    }
  });
  
  return connect(mapStateToProps, mapDispatchToProps)(hocComponent)
}

const mapStateToProps = state => ({
  assessmentModel: state.assessmentModel,
  appState: state.appState,
  passageModel: state.passageModel,
  questionModel: state.questionModel,
  answerModel: state.answerModel,
  questionStat: generateQuestionStat(state)
})

const mapDispatchToProps = dispatch => ({
  fetchAssessment: (query) => dispatch(queryAssessment(query)),
  addBulkToAppState: (data) => dispatch(addBulk(data)),
  fetchPassages: (query) => dispatch(getAllPassage(query)),
  fetchQuestions: (query) => dispatch(getAllQuestions(query)),
  fetchAppState: () => dispatch(getAppState()),
  clearAppState: () => dispatch(clearState())
})

export default withAssessmentController;

const generateQuestionStat = ({answerModel}) => {
  let questionStat = [] 
  if(answerModel.data.length) {
    questionStat = answerModel.data.map((answer) => ({"passage_id": answer.passage_id, "question_id": answer.question_id, "option_id": answer.option_id, isAnswered: true}))
  }
  return questionStat
}