import React from 'react';
import Base from '../../../components/base';
import withAssessmentController from '../../../controllers/assessment-controller';

function Passage(props) {
  if(props.appState && props.appState.data) {
    return <Base {...props}/>
  } else {
    return <div></div>
  }
}

export default withAssessmentController(Passage);