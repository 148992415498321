import environment from './base';

const API_ROOT = '/api';
const env = environment(API_ROOT, 'staging');

export default {
  ...env,
  isProduction: false,
  isDevelopment: false,
  isStaging: true
};