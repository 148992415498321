import { ASSESSMENT_COMPLETE_REQUEST_INITIATED, ASSESSMENT_COMPLETE_REQUEST_SUCCEED, ASSESSMENT_COMPLETE_REQUEST_FAILED } from "../action-type";

const initialState = {
  isLoading: false,
  data: null,
  error: null
}

export default function AssessmentCompleteReducer (state=initialState, action) {
  switch (action.type) {
    case ASSESSMENT_COMPLETE_REQUEST_INITIATED: {
      return {
        ...state,
        isLoading: true,
        error: null
      }
    }
    case ASSESSMENT_COMPLETE_REQUEST_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null
      }
    }
    case ASSESSMENT_COMPLETE_REQUEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    default: return state;
  }
}