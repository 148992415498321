import React, { useState, useEffect } from 'react';
import QuestionView from './question-view';
import PassageSectionView from './sections/passage-section-view';
import { Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { addItem } from '../store/actions/app-state-action';
import { createAnswer } from '../store/actions/answer-action';
import { toastError } from '../controllers/error-controller';

function Base(props) {
  const { addToAppState, submitAnswer, answerModel, appState } = props
  const [ selectedOption, setOption ] = useState({})
  const [ currentPassage, setCurrentPassage ] = useState({})
  const [ currentQuestion, setCurrentQuestion ] = useState({})
  const history = useHistory()
  const [ isDisable, setDisable ] = useState(true)
  const [ currentIndex, setIndex ] = useState()
  const [ showError, toogleError ] = useState(false)

  //disbale next button
  useEffect(() => {
    if(selectedOption && Object.keys(selectedOption) && Object.keys(selectedOption).length && answerModel && !answerModel.isLoading) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [selectedOption, answerModel, currentQuestion])

  //common error fuction
  const onError = (e) => {
    toastError(e)
  }

  //after successfully saving the answer updating the appState and redirection
  const updateStateAndRedirect = async (path, currentStat, questionStat, type) => {
    try {
      await addToAppState('questionStat', questionStat, type)
      await addToAppState('currentStat', currentStat)
      return history.replace(path)
    } catch (e) {
      onError(e)
    }
  }

  //making api requestion to post answer
  const saveAnswer = async () => {
    try {
      if(selectedOption && Object.keys(selectedOption).length) {
        let data = {
          "question_id": currentQuestion.question_id,
          "option_id": selectedOption.id
        }
        await submitAnswer(data)
      } else {
        toogleError(true)
      }
    } catch (e) {
      onError(e)
    }
  }

  return (
    <div className="ui segments">
      <div className="ui inverted segment padding-sm-left">
        <p className="padding-sm-left">QUESTION {currentIndex + 1} OF {appState.data.totalPassage}</p>
      </div>
      <div className="ui celled grid segment border-none box-shadow-none">
          <div className="eight wide column passage-height padding-vs">
            <Route path={`/assessment/:assessment_id/passage/:passage_id`}
              render={(props) => (
                <QuestionView {...props}
                  currentPassage={currentPassage}
                  setCurrentPassage={setCurrentPassage}
                  onError={onError}
                  setIndex={setIndex}
                />
              )}
            />
          </div>
          <div className="eight wide column padding-no">
            <Route path={`/assessment/:assessment_id/passage/:passage_id/question/:question_id`}
              render={(props) => (
                <PassageSectionView {...props}
                  currentPassage={currentPassage}
                  onError={onError}
                  updateStateAndRedirect={updateStateAndRedirect}
                  selectedOption={selectedOption}
                  currentQuestion={currentQuestion}
                  setCurrentQuestion={setCurrentQuestion}
                  setOption={setOption}
                  saveAnswer={saveAnswer}
                  isDisable ={isDisable}
                  setDisable ={setDisable}
                  currentIndex={currentIndex}
                  showError={showError}
                  toogleError={toogleError}
                />
              )}
            />
          </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  answerModel: state.answerModel
})

const mapDispatchToProps = dispatch => ({
  addToAppState: (key, value, type) => dispatch(addItem(key, value, type)),
  submitAnswer: (data) => dispatch(createAnswer(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Base);