import { findRecord, query } from "../async-actions"
import { PASSAGE_REQUEST_INITIATED, PASSAGE_REQUEST_SUCCEED, PASSAGE_REQUEST_FAILED } from "../action-type"
import { sortBy } from 'lodash';
import { onError } from "./general-action";

export const getPassage = (passage_id) => {
  return async function(dispatch) {
    try {
      dispatch(passageRequestInitiated())
      let response = await findRecord('passage', passage_id)
      return dispatch(passageRequestSucceed([response.data]))
    } catch(e) {
      return dispatch(onError(PASSAGE_REQUEST_FAILED, e))
    }
  }
}

export const getAllPassage = (q) => {
  return async function(dispatch) {
    try {
      dispatch(passageRequestInitiated())
      let response = await query('passage', q)
      return dispatch(passageRequestSucceed(response.data))
    } catch(e) {
      return dispatch(onError(PASSAGE_REQUEST_FAILED, e))
    }
  }
}

const passageRequestInitiated = () => {
  return {
    type: PASSAGE_REQUEST_INITIATED
  }
}

const passageRequestSucceed = (data) => {
  if(data && data.length) {
    data = sortBy(data, ['sort_order'])
    let length = data.length
    data = data.map((passage, index) => {
      if(length > (index + 1)) {
        passage.next_passage_id = data[index+1].id
      } else {
        passage.next_passage_id = null
      }
      return passage
    })
  }
  return {
    type: PASSAGE_REQUEST_SUCCEED,
    payload: data
  }
}