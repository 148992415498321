/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { clearState, getAppState } from '../../store/actions/app-state-action';
import { connect } from 'react-redux';
import { getAssessmentComplete } from '../../store/actions/assessment-complete-action';
import { toastError } from '../../controllers/error-controller';
import * as Sentry from '@sentry/browser';
import { initializeAxiosHeader } from '../../store/async-actions';

let timer;
const reTryCountLimit = 5;

function PostSection(props) {
  const [ reTryCount, setReTryCount ] = useState(0)
  const [ showProcessing, toggleNote ] = useState(true)
  const { clearAppState, inviteStatus, getInviteComplete, fetchAppState } = props

  useEffect(() => {
    fetchAppState().then(({ data }) => {
      if(data && Object.keys(data).length && data.c) {
        Sentry.configureScope((scope) => scope.setUser({"invite": data.c}))
        initializeAxiosHeader(data.c)
        getInviteComplete()
      }
    }).catch(toastError)
  }, [])

  const closeWindow  = () =>  {
    if(timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      clearAppState()
      window.parent.postMessage({
        exit: true
      }, "*");
      toggleNote(false)
    }, 5000)
  }

  useEffect(() => {
    if(!inviteStatus.isLoading && !inviteStatus.error && inviteStatus.data) {
      closeWindow()
    } else if(!inviteStatus.isLoading && inviteStatus.error) {
      toastError(inviteStatus.error)
      if(timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        if(reTryCount < reTryCountLimit) {
          getInviteComplete()
          setReTryCount(reTryCount + 1)
        } else {
          clearTimeout(timer)
        }
      }, 10000)
    }
  }, [inviteStatus.isLoading, inviteStatus.data, inviteStatus.error])

  return (
    <div className="ui grid height-full">
      <div className="eight wide centered column middle aligned">
        <div className="sixteen wide centered column">
          <div className="ui segments card-shadow">
            <div className="ui inverted center aligned segment margin-no">
              <span>Section Complete</span>
            </div>
            <div className="ui very padded center aligned segment">
              <p className="text-size-normal text-center margin-bottom-thirty margin-top-ten">
                { showProcessing ? "Processing your answers." : <span>Please click on the <b>Finish</b> button, if the assessment window doesn't close in 10 seconds automatically.</span>}
              </p>
              <div className="animated-background center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  inviteStatus: state.inviteStatus
})

const mapDispatchToProps = dispatch => ({
  clearAppState: () => dispatch(clearState()),
  getInviteComplete: () => dispatch(getAssessmentComplete()),
  fetchAppState: () => dispatch(getAppState())
})

export default connect(mapStateToProps, mapDispatchToProps)(PostSection);