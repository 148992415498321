import React from 'react';

function Button(props) {
  const { label, className, isDisable, isLoading } = props
  return (
    <div
      className={`${className} ${(isDisable || isLoading) && "disabled"} ${isLoading && 'loading'}`}
      onClick={props.onClick}>
      { label || 'Next' }
    </div>
  )
}

export default Button;