import React from 'react';
import DOMPurify from 'dompurify';

//type: HTML element
//content: Content that need to be  sanitized
export default function SanitizeContent(props) {
  const { content, type, className , htmlFor } = props
  const sanitizeContent = DOMPurify.sanitize(content)

  if(type) {
    const CustomElement = React.createElement(type, {className, htmlFor, dangerouslySetInnerHTML:{__html: sanitizeContent}})
    return CustomElement
  } else {
    return <div dangerouslySetInnerHTML={{__html: sanitizeContent}} className={className}/>
  }
}