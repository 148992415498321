import React from 'react';

function Header(props) {

    return (
      <div className="ui segment bg-dark print-report-header">
        <div className="ui grid">
          <div className="nine wide left aligned column">
            <p className="text-color-white text-weight-bold text-size-large margin-bottom-no text-size-twentyfour padding-sm-left padding-bg-top">CASE STUDY REPORT</p>
            <p className="text-color-white padding-sm-left print-report-header-assessment-name">{props.data.assessment && props.data.assessment.name}
              <span className="print-report-header-seperator">|</span>{props && props.formatedDate}
            </p>
            </div>
          <div className="seven wide column print-report-header-score">
            <p className="circle-score padding-bg-top text-color-white">{props.data && props.data.score}</p>
            <p className="rectangle-score text-color-white print-report-header-score-overall-score">Overall Score</p>
          </div>
        </div>
      </div>
    )
  }

export default Header;