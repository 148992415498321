import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-less/semantic.less'
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import 'react-toastify/dist/ReactToastify.css';
import environment from 'environment';

Sentry.init({
  dsn: "https://6dc03d503c45468fb4e5153cd18f14a3@o286634.ingest.sentry.io/5239682",
  release: process.env.CI_COMMIT_TAG,
  enabled: process.env.NODE_ENV === "production",
  environment: environment.envName
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
