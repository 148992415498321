import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { flatten, groupBy, reduce, forIn, isNil } from 'lodash';

import { onError } from '../store/actions/general-action';
import { queryReport } from '../store/actions/report-action';
import { initializeAxiosHeader } from '../store/async-actions';

import CoverPage from '../components/report-ui/cover-page';
import Header from '../components/report-ui/header';
import QuestionContent from '../components/question/question-content';
import GraphContainer from '../components/report-ui/graph/graph-container';
import QuestionList from '../components/question/question-list';
import QuestionOption from '../components/question/question-options';

function Report(props) {
  const { fetchReport, reportModel, chartData } = props
  const { search } = useLocation()
  const { c } = parse(search)
  const { data, isLoading, error } = reportModel
  const [ isDataLoaded, setDataLoaded ] = useState(false)
  const [ fullName, setfullName ] = useState("")
  const [ formatedDate, setFormatedDate ] = useState()

  useEffect(() => {
    if(c) {
      initializeAxiosHeader(c)
      fetchReport()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!isLoading && data && Object.keys(data).length) {
      setDataLoaded(true)
      
      if(data && data.assessment && data.assessment.completed_at) {
        const date = new Date(data.assessment.completed_at);
        let options = { year: '2-digit', month: 'short', day: 'numeric' };
        let formated = new Intl.DateTimeFormat('en-GB', options).format(date)
        formated = formated.split(' ').join('-')
        setFormatedDate(formated)
      }

      if(data && data.candidate && data.candidate.first_name){
        let name = "";
        if(data.candidate.middle_name){
          name=`${data.candidate.first_name} ${data.candidate.middle_name} ${data.candidate.last_name}`
        } else {
          name=`${data.candidate.first_name} ${data.candidate.last_name}`
        }
        setfullName(name);
      }
    } else if(!isLoading && error) {
      onError(error)
    }
  }, [isLoading, data, error])
  
  if(isDataLoaded) {
    return (
      <>
       <CoverPage data={data} formatedDate={formatedDate} fullName={fullName} c={c}/>
       <table>
          <tbody>
            <tr>
              <td>
                <Header data={data} formatedDate={formatedDate}/>
              </td>
            </tr>
            <tr>
              <td className="print-report-graph-container">
                <GraphContainer chartData={chartData} />
              </td>
            </tr>
            <tr>
              <td className="margin-top-twenty">
                {data && data.assessment && data.assessment.passage.map((passage, pIndex) => {
                  return (
                    <QuestionContent key={passage.id} content={passage.content} pIndex={pIndex + 1} isReport={true} >
                      {passage.question.map((question, qIndex) => (
                        <QuestionList key={question.id} content={question.content} qIndex={qIndex + 1} pIndex={pIndex + 1} isReport={true} >
                          <QuestionOption option={question.option} isReport={true} />                       
                        </QuestionList>
                      ))}
                    </QuestionContent>
                  )  
                })}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="ui equal width padded grid bg-black print-report-footer">
                  <div className="row">
                    <div className="black column">
                      <span className="padding-sm-left">Copyright © 2020 Talview &nbsp;|&nbsp; All Rights Reserved</span>
                    </div>
                    <div className="black column text-right">
                      <span className="padding-sm-right">{fullName}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      
      </>
    )
  }
  else {
    return (
      <div className="ui active centered inline loader report-loading"></div>
    )
  }
}

const mapStateToProps = state => ({
  reportModel: state.reportModel,
  chartData: generateChartData(state.reportModel)
})

const mapDispatchToProps = dispatch => ({
  fetchReport: () => dispatch(queryReport())
})

export default connect(mapStateToProps, mapDispatchToProps)(Report);

function generateChartData({isLoading, data, error}) {
  const { assessment } = data
  if(!isLoading && !error && assessment) {
    //mapping the skills in one array. it will have dublicate skills
    let skills = assessment.passage.map((passage) => {
      return passage.question.map((question, i) => ({
        id: question.skill.id,
        y: !isNil(question.score) ? question.score : 0,
        name: question.skill.name
      }))
    })
    
    let allSkills = flatten(skills)                       //skills comes as arrays of array
    let groupSkills = groupBy(allSkills, 'id')            //grouping skills based on id
    let filteredSkills = []

    //calculating the total skill score for each skills if same skill tag to multiple questions
    forIn(groupSkills, (skill) => {
      let totalScore = 0
      if(skill.length > 1) {
        totalScore = reduce(skill, (sum, s) => {
          return sum + parseInt(s.y)
        }, 0)
      } else {
        totalScore = skill[0].y 
      }
      skill.forEach((s) => (s.y = totalScore))
      filteredSkills.push(skill[0])
      return skill
    })

    //adding color
    filteredSkills = filteredSkills.map((skill, i) => ({...skill, color: i%2 ? "#3A8B94": "#F7921E"}))
    return filteredSkills
  }
  return []
}